import React, { Component } from "react";
import { Rating, Segment, Label } from "semantic-ui-react";
import CustomIcon, { nameMap } from "./CustomIcon";

const colors = {
  1: "orange",
  2: "orange",
  3: "green",
  4: "purple",
  5: "purple",
};

const labelText = {
  1: "Familiar",
  2: "Familiar",
  3: "Experienced",
  4: "Advanced",
  5: "Advanced",
};

class SkillRating extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const segmentSize = 100;
    return (
      <Segment
        compact
        style={{
          width: "" + segmentSize + "px",
          height: "" + (segmentSize + 77) + "px",
          flex: "0 1 auto",
        }}
        textAlign="center"
      >
        <Label
          compact
          size="large"
          attached="top"
          color="black"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {nameMap[this.props.name]}
        </Label>
        <CustomIcon size={2} name={this.props.name} />
        {/*<Rating
          icon="star"
          defaultRating={this.props.stars}
          maxRating={5}
          disabled
          size="large"
          textAlign="center"
        />*/}
        <Label
          compact
          size="medium"
          attached="bottom"
          color={colors[this.props.stars]}
          style={{ whiteSpace: "pre-wrap" }}
        >
          {labelText[this.props.stars]}
        </Label>
      </Segment>
    );
  }
}

export default SkillRating;
