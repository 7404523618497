import React from "react";
import logo from "./logo.svg";
import "./App.css";
import MainPage from "./MainPage";

function MobileApp() {
  document.body.style.display = "flex";
  return (
    <div className="App">
      <MainPage isMobile={true}></MainPage>
    </div>
  );
}

export default MobileApp;
