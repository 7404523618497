import React, { Component } from "react";

const prefix = "https://img.icons8.com/color/";
const suffix = "/000000/";

const iconTable = {
  javascript: "javascript",
  java: "java-coffee-cup-logo",
  cplusplus: "c-plus-plus-logo",
  csharp: "c-sharp-logo",
  swift: "swift",
  python: "python",
  aws: "amazon-web-services",
  docker: "docker",
  git: "git",
  go: "golang",
  postgres: "postgreesql",
  react: "react-native",
  kubernetes: "kubernetes",
  graphql: "graphql",
  hadoop: "hadoop-distributed-file-system",
  nginx: "nginx",
  sql: "sql",
  tableau: "tableau-software",
  visualbasic: "vb",
};

const fullUrlIcons = {
  spark: "/spark.png",
  spark2: "/spark2.png",
  unity: "https://i.redd.it/estpv0c6iw841.jpg",
  codedeploy: "aws_icons/codedeploy.png",
  codepipelines: "aws_icons/codepipelines.png",
  ec2: "aws_icons/ec2.png",
  ecs: "aws_icons/ecs.png",
  gcp: "gcp.png",
  elasticsearch: "aws_icons/elasticsearch.png",
  emr: "aws_icons/emr.png",
  fargate: "aws_icons/fargate.png",
  kinesis: "aws_icons/kinesis.png",
  lambda: "aws_icons/lambda.png",
  route53: "aws_icons/route53.png",
  s3: "aws_icons/s3.png",
  cicd: "cicd.png",
  sns: "aws_icons/sns.png",
  sqs: "aws_icons/sqs.png",
  kafka: "https://svn.apache.org/repos/asf/kafka/site/logos/originals/png/ICON%20-%20Black%20on%20Transparent.png",
  burp: "/burp.png",
  terraform: "https://symbols.getvecta.com/stencil_97/45_terraform-icon.0fedccc574.png"
};

export const nameMap = {
  javascript: "JavaScript",
  java: "Java",
  cplusplus: "C++",
  csharp: "C#",
  swift: "Swift",
  python: "Python",
  aws: "AWS",
  gcp: "GCP",
  docker: "Docker",
  git: "Git",
  go: "Go",
  postgres: "PostgreSQL",
  react: "React",
  kubernetes: "Kubernetes",
  kafka: "Apache Kafka",
  graphql: "GraphQL",
  hadoop: "Hadoop",
  nginx: "NginX",
  sql: "SQL",
  tableau: "Tableau",
  visualbasic: "Visual Basic",
  spark: "Apache Spark",
  spark2: "Apache Spark",
  unity: "Unity",
  codedeploy: "CodeDeploy",
  codepipelines: "CodePipelines",
  ec2: "EC2",
  ecs: "ECS",
  elasticsearch: "ElasticSearch",
  emr: "EMR",
  fargate: "Fargate",
  kinesis: "Kinesis",
  lambda: "Lambda",
  route53: "Route53",
  s3: "S3",
  cicd: "CI/CD",
  sns: "SNS",
  sqs: "SQS",
  burp: "Burp Suite",
  terraform: "Terraform"
};

const sizeTable = {
  1: "48",
  2: "96",
  3: "144",
  4: "240",
  5: "480",
};

class CustomIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let url =
      iconTable[this.props.name] != null
        ? prefix +
          sizeTable[this.props.size] +
          suffix +
          iconTable[this.props.name] +
          ".png"
        : fullUrlIcons[this.props.name];
    const sizeStyle = {
      width: sizeTable[this.props.size] + "px",
      height: "auto",
    };
    return (
      <img
        src={url}
        style={iconTable[this.props.name] != null ? {} : sizeStyle}
      />
    );
  }
}

export default CustomIcon;
