import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import {
  Header,
  Container,
  Grid,
  Button,
  Image,
  Segment,
  Label,
  Divider,
  Statistic,
} from "semantic-ui-react";
import CustomIcon, { nameMap } from "./CustomIcon";
import MediaQuery from "react-responsive";

class LargeEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  DisplayTechStack() {
    const segmentSize = "96px";
    return (
      <Fade fraction={0.92}>
        <Segment.Group
          horizontal
          compact
          raised
          style={{ display: "inline-flex", "margin-bottom": 0 }}
        >
          {this.props.techStack.map((item, index) => (
            <Fade bottom delay={300 * index} fraction={1}>
              <Segment
                compact
                style={{
                  width: segmentSize,
                  height: segmentSize,
                  flex: "0 1 auto",
                }}
                textAlign="center"
              >
                <CustomIcon size={1} name={item} />
                <Label compact size="tiny" attached="bottom" color="black">
                  {nameMap[item]}
                </Label>
              </Segment>
            </Fade>
          ))}
        </Segment.Group>
      </Fade>
    );
  }

  render() {
    let hasButtons =
      !this.props.isMobile &&
      (this.props.siteLink != null ||
        this.props.appLink != null ||
        this.props.gitRepo != null ||
        this.props.linesCode != null);
    let hasButtonsAsideFromCodeLines =
      !this.props.isMobile &&
      (this.props.siteLink != null ||
        this.props.appLink != null ||
        this.props.gitRepo != null);
    return (
      <div>
        <Fade left fraction={0.9}>
          <Container textAlign="left">
            <Segment raised>
              <Grid>
                <Grid.Row style={{ "padding-bottom": 0 }}>
                  <Grid.Column>
                    <Grid>
                      <Grid.Row columns={2} style={{ "padding-bottom": 0 }}>
                        <Grid.Column>
                          {!this.props.companyLogoPath ? (
                            <Header as="h3">{this.props.entryTitle}</Header>
                          ) : (
                            <Segment compact>
                              <Image
                                src={this.props.companyLogoPath}
                                size="small"
                              />
                            </Segment>
                          )}
                        </Grid.Column>
                        <Grid.Column textAlign="right" verticalAlign="top">
                          <Header
                            as="h5"
                            sub
                            style={{
                              whiteSpace: "pre-wrap",
                              "font-size": "1em",
                            }}
                          >
                            {this.props.datesInfo}
                            {"\n"}
                            {this.props.roleLocation}
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Header as="h4">{this.props.roleTitle}</Header>
                    <p style={{ margin: "1em 0 0em", "font-size": "110%"}}>
                      {this.props.description}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ "padding-top": 0 }}>
                  <Grid.Column
                    width={hasButtons ? 13 : 16}
                    verticalAlign="bottom"
                    textAlign={hasButtons ? "left" : "center"}
                  >
                    {this.props.techStack != null && (
                      <span>{this.DisplayTechStack()}</span>
                    )}
                  </Grid.Column>
                  {hasButtons && (
                    <Grid.Column
                      width={3}
                      textAlign="right"
                      verticalAlign={
                        hasButtonsAsideFromCodeLines ? "bottom" : "bottom"
                      }
                    >
                      <MediaQuery minDeviceWidth={1224}>
                        <Fade
                          fraction={0.9}
                          delay={400 + this.props.techStack.length * 300}
                        >
                          <div>
                            {this.props.linesCode != null && (
                              <Statistic
                                size="mini"
                                style={{ "margin-bottom": "0.3em" }}
                              >
                                <Statistic.Label
                                  style={{
                                    "font-size":
                                      this.props.siteLink != null &&
                                      this.props.appLink == null &&
                                      this.props.gitRepo == null
                                        ? "12.5px"
                                        : "13px",
                                  }}
                                >
                                  lines of code
                                </Statistic.Label>
                                <Statistic.Value>
                                  {this.props.linesCode}
                                </Statistic.Value>
                              </Statistic>
                            )}
                            <Button.Group vertical size="small">
                              {this.props.gitRepo != null && (
                                <Button
                                  compact
                                  role="a"
                                  href={this.props.gitRepo}
                                >
                                  Code Repo
                                </Button>
                              )}
                              {this.props.appLink != null && (
                                <Button
                                  compact
                                  role="a"
                                  href={this.props.appLink}
                                >
                                  Store Page
                                </Button>
                              )}
                              {this.props.siteLink != null && (
                                <Button
                                  compact={
                                    this.props.siteLink != null &&
                                    this.props.appLink == null &&
                                    this.props.gitRepo == null
                                      ? false
                                      : true
                                  }
                                  role="a"
                                  href={this.props.siteLink}
                                >
                                  Visit Site
                                </Button>
                              )}
                            </Button.Group>
                          </div>
                        </Fade>
                      </MediaQuery>
                    </Grid.Column>
                  )}
                </Grid.Row>
              </Grid>
            </Segment>
          </Container>
        </Fade>
      </div>
    );
  }
}

export default LargeEntry;
