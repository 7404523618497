import React, { Component } from "react";
import SkillRating from "./SkillRatingTile";
import {
  Grid,
  GridRow,
  GridColumn,
  Container,
  Divider,
} from "semantic-ui-react";
import Fade from "react-reveal/Fade";
import { darkBackgroundHeaderColor } from "./MainPage";

class SkillCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Container text textAlign="left">
        <Fade bottom fraction={0.85}>
          <h1 style={{ "font-size": "36px", color: darkBackgroundHeaderColor }}>
            {this.props.categoryName}
          </h1>
        </Fade>

        <Divider hidden></Divider>
        <Divider hidden></Divider>
        <Grid centered>
          <GridRow>
            {this.props.entries.map((entry, index) => (
              <GridColumn
                width={3}
                style={
                  this.props.isMobile
                    ? { "padding-left": 0, "margin-bottom": "8px" }
                    : { "margin-bottom": "8px" }
                }
              >
                <Fade
                  right={!this.props.isMobile}
                  delay={200 * (index % 5) + 100 * index}
                  fraction={0.7}
                >
                  <SkillRating name={entry[0]} stars={entry[1]} />
                </Fade>
              </GridColumn>
            ))}
          </GridRow>
        </Grid>
        <Divider hidden></Divider>
        <Divider hidden></Divider>
      </Container>
    );
  }
}

export default SkillCategory;
