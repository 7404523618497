import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import LargeEntry from "./LargeEntry";
import { Divider, Container } from "semantic-ui-react";
import { darkBackgroundHeaderColor } from "./MainPage";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const entriesList = this.props.entries.map((entry, number) => (
      <div>
        {entry}
        {number < this.props.entries.Length - 1 && <Divider horizontal />}
        <Divider hidden></Divider>
        <Divider hidden></Divider>
      </div>
    ));
    return (
      <Container text textAlign="left">
        <Fade bottom fraction={0.85}>
          <h1 style={{ "font-size": "36px", color: darkBackgroundHeaderColor }}>
            {this.props.categoryName}
          </h1>
        </Fade>
        <Divider hidden></Divider>
        <Divider hidden></Divider>
        <div>{entriesList}</div>
        <Divider hidden></Divider>
        <Divider hidden></Divider>
      </Container>
    );
  }
}

export default Category;
