import React from "react";
import logo from "./logo.svg";
import "./App.css";
import MainPage from "./MainPage";
import Particles from "react-particles-js";
import { Icon, Divider } from "semantic-ui-react";
import Fade from "react-reveal/Fade";

const fx = (
  <Particles
    style={{
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    }}
    params={{
      particles: {
        number: {
          value: 70,
        },
        size: {
          value: 3,
        },
      },
      interactivity: {
        events: {},
      },
    }}
  />
);

function App() {
  document.body.style.display = "block";
  return (
    <div>
      <div className="App">
        {fx}
        <MainPage style={{ position: "relative" }}></MainPage>
      </div>
      
    </div>
  );
}

export default App;
