import React, { Component } from "react";
import Category from "./Category";
import LargeEntry from "./LargeEntry";
import CustomIcon from "./CustomIcon";

import SkillCategory from "./SkillCategory";
import {
  Image,
  Container,
  Header,
  Divider,
  Grid,
  GridRow,
  GridColumn,
  Segment,
  Button,
} from "semantic-ui-react";
import Fade from "react-reveal/Fade";
import scrollToComponent from "react-scroll-to-component";

export const darkBackgroundHeaderColor = "gold";

class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Divider hidden horizontal></Divider>

        <Fade>
          <Container
            text
            style={{
              height: this.props.isMobile ? "175vh" : "100vh",
              display: "flex",
              "justify-content": "center",
              "align-items": "center",
              whiteSpace: "pre-wrap",
            }}
          >
            <Header
              as="h1"
              style={{
                fontSize: "3rem",
                whiteSpace: "pre-wrap",
                color: darkBackgroundHeaderColor,
              }}
            >
              <span style={{ color: "gold" }}>Chase Labar</span>
              {"\n"}
              <span style={{ color: "snow" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;software security engineer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <Divider hidden horizontal></Divider>
              <Grid textAlign="center">
                <GridRow columns={"equal"}>
                  <GridColumn>
                    <Button
                      basic
                      size="huge"
                      onClick={() =>
                        scrollToComponent(this.scrollTarget, {
                          offset: -20,
                          align: "top",
                          duration: 1000,
                        })
                      }
                    >
                      <span>About Me</span>
                    </Button>
                  </GridColumn>
                  <GridColumn>
                    <Button
                      basic
                      size="huge"
                      onClick={() =>
                        scrollToComponent(this.scrollExperience, {
                          offset: -20,
                          align: "top",
                          duration: 1000,
                        })
                      }
                    >
                      <span>Experience</span>
                    </Button>
                  </GridColumn>
                  <GridColumn>
                    <Button
                      basic
                      size="huge"
                      onClick={() =>
                        scrollToComponent(this.scrollSkills, {
                          offset: -20,
                          align: "top",
                          duration: 1000,
                        })
                      }
                    >
                      <span>Skills</span>
                    </Button>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Header>
            <br />
          </Container>
        </Fade>
        <Fade fraction={0.45}>
          <Container style={{ height: "600px" }}>
            <section
              ref={(section) => {
                this.scrollTarget = section;
              }}
            ></section>
            <Header
              as="h1"
              style={{ fontSize: "2.5rem", color: darkBackgroundHeaderColor }}
            >
              About Me
            </Header>
            <Divider hidden horizontal></Divider>
            <Image src="/me.jpg" size="medium" circular centered bordered />

            <Divider hidden horizontal></Divider>
            <Fade bottom fraction={1}>
              <Container text>
                <Grid>
                  <GridRow columns="equal">
                    <GridColumn textAlign="left" floated="left">
                      <Segment>
                        <Header as="h1" textAlign="center" verticalAlign="middle">Who am I?</Header>
                        <Header as="h3" style={{ fontWeight: "500" }}>
                          Hello there! I'm a resident of Austin, Texas, seeking engineering roles on cutting edge security teams.
                          I've had a passion for coding since I was 9 years old,
                          and I'm always excited to learn something new. I also hold a BS in Computer Science from UT Austin. My
                          major interests include cybersecurity, cloud computing,
                          and game development.
                        </Header>
                      </Segment>
                    </GridColumn>
                    <GridColumn textAlign="right" verticalAlign="middle">
                      <Segment compact textAlign="left" floated="right">
                        <Header as="h1" textAlign="center" verticalAlign="middle">Contact Info</Header>
                        <Header
                          as="h3"
                          style={{ whiteSpace: "pre-wrap", fontWeight: "500" }}
                        >
                          {"chaselabar@gmail.com"}
                          {"\n"}
                          {"\n"}
                          {"702-580-9699"}
                          {"\n"}
                          {"\n"}
                          <a href="http://www.linkedin.com/in/chase-labar-14b7b33b">
                            LinkedIn
                          </a>
                        </Header>
                      </Segment>
                    </GridColumn>
                  </GridRow>
                </Grid>
              </Container>
            </Fade>
          </Container>
        </Fade>
        <Divider hidden horizontal></Divider>
        <Divider hidden horizontal></Divider>
        <Divider hidden horizontal></Divider>
        <Divider hidden horizontal></Divider>
        <Divider hidden horizontal></Divider>
        <Divider hidden horizontal></Divider>
        <Divider hidden horizontal></Divider>
        <Divider hidden horizontal></Divider>
        <Divider hidden horizontal></Divider>
        <Divider hidden horizontal></Divider>
        <Divider hidden horizontal></Divider>
        <Divider hidden horizontal></Divider>
        <Divider hidden horizontal></Divider>
        <Divider hidden horizontal></Divider>
        <Divider hidden horizontal></Divider>
        {this.props.isMobile && (
          <span>
            <Divider hidden horizontal></Divider>
            <Divider hidden horizontal></Divider>
            <Divider hidden horizontal></Divider>
          </span>
        )}
        <section
          ref={(section) => {
            this.scrollExperience = section;
          }}
        ></section>
        <Category
          categoryName="Experience"
          entries={[
            <LargeEntry
              isMobile={this.props.isMobile}
              entryTitle="Praetorian"
              companyLogoPath="/praetorian_logo.png"
              roleTitle="Security Engineer"
              roleLocation="Austin, TX"
              datesInfo="February 2021 – Present"
              description="Developed automated attack surface discovery and vulnerability detection system. Designed and developed secure
              customer cloud integration system for GCP, AWS, & Azure, enabling continuous 'inside-out' attack surface
              discovery. Forklifted 
              legacy Google App Engine static code analysis product into Kubernetes/Kafka architecture. Consulted for clients (including several Fortune 500 companies) as a pentester and security adviser for web and mobile applications, 
              as well as a security auditor for cloud environments, achieving an overall personal NPS of 100. Worked with clients to ensure that identified security risks were
              fully understood and properly remediated using security best practices. Designed vulnerability template hydration system,
              enabling security consultants to quickly create standardized dossiers for automatically detected vulnerabilities."
              techStack={["go", "gcp", "kubernetes", "terraform", "kafka", "burp"]}
            />,
            <LargeEntry
              isMobile={this.props.isMobile}
              entryTitle="Pioneer Flight Search Engine"
              roleTitle="Founder - Maximus Software LLC"
              roleLocation="Austin, TX"
              datesInfo="August 2019 – December 2020"
              description="Developed Python backend flight search service to quickly calculate optimal flight rankings using loss functions, flight data caching in GraphQL, and multithreaded parallel API calls; created backend analytics service using AWS Kinesis and Lambda 
              to stream events to ElasticSearch, recording both user behavior and search results; designed Google Analytics 
              integrated React frontend using Google Places API for location search, Framer Motion for animations; all services deployed and 
              load-balanced via ECS"
              techStack={[
                "python",
                "javascript",
                "react",
                "aws",
                "docker",
                "graphql",
              ]}
            />,
            <LargeEntry
              isMobile={this.props.isMobile}
              entryTitle="Amazon.com"
              companyLogoPath="/amazon.png"
              roleTitle="Software Development Engineer Intern"
              roleLocation="Austin, TX"
              datesInfo="May 2017 – August 2017"
              description="Created scalable microservice using Amazon Web Services (Elastic MapReduce, Redshift, S3,
              EC2), Apache Spark, and Java to automatically detect items with uncharacteristically high
              shipping costs and send detailed reports of those items to data scientists for further analysis"
              techStack={["java", "python", "aws", "spark", "hadoop"]}
            />,
            <LargeEntry
              isMobile={this.props.isMobile}
              entryTitle="Everi"
              companyLogoPath="/everi.png"
              roleTitle="Software Developer Intern"
              roleLocation="Austin, TX"
              datesInfo="July 2016 – August 2016"
              description="Created VBA extension for Microsoft Visio 2016 that allows users to encode casino floor plans
              with slot machine ID’s; connected floor plans and SQL database to generate Tableau
              visualizations of analytics data; presented Visio tool and visualizations to site administrators"
              techStack={["visualbasic", "tableau", "sql"]}
            />,
          ]}
        ></Category>
        <br />
        <br />
        <section
          ref={(section) => {
            this.scrollSkills = section;
          }}
        ></section>
        <SkillCategory
          isMobile={this.props.isMobile}
          categoryName="Programming Languages"
          entries={[
            ["python", 5],
            ["java", 4],
            ["go", 4],
            ["csharp", 4],
            ["javascript", 3]
          ]}
        />
        <br />
        <br />
        <SkillCategory
          isMobile={this.props.isMobile}
          categoryName="Software Tools"
          entries={[
            ["docker", 4],
            ["git", 4],
            ["kubernetes", 4],
            ["react", 4],
            ["burp", 4],
            ["gcp", 4],
            ["aws", 4],
            ["terraform", 4],
            ["kafka", 3],
            ["graphql", 3],
            
          ]}
        />
        <br />
        <br />
        <Category
          categoryName="Projects"
          entries={[
            <LargeEntry
              isMobile={this.props.isMobile}
              entryTitle="MyClip.is"
              roleTitle="Creator & Developer"
              description="Built web app that provides cloud “clipboards” (text & files) that can be quickly shared by URL 
              or by geolocation to any device with a web browser; S3, Lambda, and ECS Docker microservice back-end"
              datesInfo="Personal Project"
              techStack={["python", "aws", "docker"]}
              siteLink="http://myclip.is"
              gitRepo="https://bitbucket.org/labar-utcs/aws-universal-clipboard/src/master/"
              linesCode="683"
            />,
            <LargeEntry
              isMobile={this.props.isMobile}
              entryTitle='"Deep Dive" - iOS Game'
              roleTitle="Creator & Developer"
              description="Designed and developed action-arcade video game for iOS App Store using Unity game engine
              with C# scripts; signed and marketed by mobile game publisher Voodoo Games"
              datesInfo="Personal Project"
              techStack={["csharp", "unity"]}
              appLink="https://apps.apple.com/us/app/deep-dive-the-endless-ocean/id1471014730?mt=8"
              linesCode="6,795"
            />,
            <LargeEntry
              isMobile={this.props.isMobile}
              entryTitle="ClimateBuddy"
              roleTitle="Backend Developer & DevOps"
              description="Created backend API using Python and Flask to provide country and
              city data (population, air quality, GDP, geolocation, etc.); scraped publicly
              available databases and APIs to build PostgreSQL database of country and city data;
              deployed both frontend and backend servers on
              AWS EC2, using GitLab CI/CD and AWS CodePipelines to automate deployments
              of each new commit"
              datesInfo="Group University Project"
              techStack={[
                "python",
                "javascript",
                "react",
                "aws",
                "docker",
                "postgres",
              ]}
              siteLink="https://climatebuddy.xyz"
              gitRepo="https://gitlab.com/chase_labar_utexas/climatebuddy"
              linesCode="4,559"
            />,
          ]}
        ></Category>
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default MainPage;
