import React from "react";
import ReactDOM from "react-dom";
import "semantic-ui-css/semantic.min.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Helmet } from "react-helmet";
import Center from "react-center";
import MediaQuery from "react-responsive";
import MobileApp from "./MobileApp";

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <meta name="viewport" content="width=device-width, maximum-scale=1.5" />
      <script src="https://npmcdn.com/react@^0.14/dist/react.min.js"></script>
      <script src="https://npmcdn.com/react-dom@^0.14/dist/react-dom.min.js"></script>
      <script src="https://npmcdn.com/react-center"></script>
    </Helmet>
    <Center>
      <MediaQuery minDeviceWidth={1224}>
        <App />
      </MediaQuery>
      <MediaQuery maxDeviceWidth={1224}>
        <MobileApp />
      </MediaQuery>
    </Center>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
